<template>
  <div class="shared-multiplier" :style="rootStyles">
    <SharedText class="shared-multiplier__value" :weight="FontWeights.BLACK">
      {{ props.startSymbol + props.multiplier || '' }}
    </SharedText>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed } from 'vue';
import type { ISharedMultiplierProps } from './SharedMultiplier.types';
import { FontWeights } from '~/types/SharedFont.types';

const DEFAULT_MULTIPLIER_SYMBOL = 'x';

const props = withDefaults(defineProps<ISharedMultiplierProps>(), {
  startSymbol: DEFAULT_MULTIPLIER_SYMBOL,
});

const rootStyles = computed(() => {
  const properties: CSSProperties = {};

  if (props.bgColor) Object.assign(properties, GlobalUtils.CSS.parseColor(props.bgColor));

  return properties;
});
</script>

<style src="./SharedMultiplier.scss" scoped lang="scss"></style>
